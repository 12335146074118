<template>
  <CardBase
    :loading="loading"
    number="1-2"
    :title="$t('sales_target')"
    :help-button="false"
    :custom-date="date"
  >
    <template v-slot:content-body>
      <v-row>
        <v-col cols="12">
          <v-card flat color="grey lighten-3" class="rounded-0 text-center">
            <v-card-text>
              <div class="main--text font-weight-medium text-heading-5">
                {{ $t("target_for_this_term") }}
              </div>
              <div class="main--text font-weight-medium text-heading-5">
                {{ salesTerm }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="text-center py-6">
          <div class="secondary--text font-weight-medium text-heading-9">
            {{ salesTarget | toCurrency }}
          </div>
        </v-col>

        <v-col cols="12" class="text-right py-2">
          <v-btn
            class="main--text lighten-4 font-weight-medium"
            text
            small
            :ripple="false"
            :to="{ name: 'goals_settings' }"
            :disabled="loading"
          >
            {{ $t("sales_target_set") }}

            <v-icon right size="10">
              $grayRightArrow
            </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-card flat color="grey lighten-3" class="rounded-0 text-center">
            <v-card-text>
              <div class="main--text font-weight-medium text-heading-5">
                {{ $t("sales_target_monthly") }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <TableMonthlyTarget :items="goals" />
        </v-col>
        <v-col cols="12" class="text-center">
          <div class="font-weight-medium text-heading-1 footer--text">
            {{ $t("sales_target_set_message") }}
          </div>
        </v-col>
      </v-row>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import { toCurrency } from "@/filters";
import TableMonthlyTarget from "@/components/ui/TableMonthlyTarget";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import mixins from "@/mixins/index";

export default {
  name: "GoalsCardSalesTarget",
  mixins: [mixins],
  components: { TableMonthlyTarget, CardBase },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  mounted() {
    let start_date = this.getGoalReport.year.start_date;
    let end_date = this.getGoalReport.year.end_date;

    this.salesTerm = `
      ${this.getGoalReport.year.term}期
      ${dayjs(start_date).format("YYYY年M月")}
      ～
      ${dayjs(end_date).format("YYYY年M月")}
      `;

    this.calculateGoalValue();
  },
  computed: {
    ...mapGetters(["getGoalReport"]),
    date() {
      return this.getGoalReport.date;
    }
  },
  data() {
    return {
      salesTarget: 0,
      salesTerm: "",
      goals: []
    };
  },
  methods: {
    getActualSaleAchievedTextColor(target, sale) {
      if (sale === 0) {
        return "goals-no-data--text";
      } else if (sale > target) {
        return "goals-achieved--text";
      } else {
        return "goals-not-achieved--text";
      }
    },
    getActualSaleText(sale) {
      return sale !== 0 ? toCurrency(sale) : "¥--";
    },
    calculateGoalValue() {
      let goalValue = 0;

      this.goals = [];
      for (const [
        index,
        goal
      ] of this.getGoalReport.year.monthly_goals.entries()) {
        goalValue += goal.goal;
        this.goals.push({
          id: goal.month.id,
          month: index + 1,
          target_monthly_sale: goal.goal,
          actual_sale: goal.sale,
          is_past: dayjs(goal.month.id).isBefore(dayjs().format("YYYY-MM"))
        });
      }
      this.salesTarget = goalValue;
    }
  }
};
</script>

<style scoped>
.footer--text {
  color: #757575;
}
</style>
