<template>
  <CardBase
    :loading="loading"
    number="1-1"
    :title="$t('sales_figure')"
    :show-date="false"
    modal-title="売上数値の見方"
    modal-component="GoalsMainModal"
  >
    <template v-slot:content-body>
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-right text-center pt-0">
          <h4 class="font-weight-medium text-heading-5 main--text">
            {{ $t("sales_figure_month", { month: month }) }}
          </h4>
          <h5 class="font-weight-medium text-heading-1 main--text lighten-2">
            {{ $t("sales_figure_subtitle", { date: today }) }}
          </h5>
        </v-col>

        <v-col cols="12">
          <div class="text-center">
            <AchievementRateChart
              :total="getGoalReport.month.goal_progress_percentage"
              :progress="getGoalReport.month.actual_progress_percentage"
              :success="getGoalReport.month.success_percentage"
            />
          </div>
        </v-col>

        <v-col cols="12">
          <ListItemSalesFigures
            icon="$flag"
            color="primary"
            :title="
              $t('sales_figure_performance', {
                month: getGoalReport.month.name
              })
            "
            :value="getGoalReport.month.sale_to_date"
            :value-subtitle="
              $t('sales_achievement_rate_from_target', {
                percentage: getGoalReport.month.success_percentage
              })
            "
          />
        </v-col>

        <v-col cols="12">
          <ListItemSalesFigures
            icon="$flag"
            color="accent"
            :title="$t('sales_figure_planned_sales')"
            title-color="main--text lighten-2"
            :subtitle="`${monthTerm}`"
            :value="getGoalReport.month.goal_to_date"
            value-color="main--text lighten-2"
          />
        </v-col>

        <v-col cols="12">
          <ListItemSalesFigures
            icon="$flag"
            color="accent"
            :title="
              $t('sales_figure_goal', { month: getGoalReport.month.name })
            "
            title-color="main--text lighten-2"
            :value="getGoalReport.month.goal"
            value-color="main--text lighten-2"
          />
        </v-col>

        <v-col cols="12" class="py-8">
          <v-divider class="mx-6 sales-figures-divider"></v-divider>
        </v-col>

        <v-col cols="12" class="text-right text-center">
          <h4 class="font-weight-medium text-heading-5 main--text">
            {{ $t("sales_figure_term") }}
          </h4>
          <h5 class="font-weight-medium text-heading-1 main--text lighten-2">
            {{ salesTerm }}
          </h5>
        </v-col>

        <v-col cols="12">
          <ListItemSalesFigures
            icon="$flag"
            color="primary"
            :title="$t('sales_figure_term_result')"
            :value="getGoalReport.year.sale_to_date"
            :value-subtitle="
              $t('sales_achievement_rate_from_target', {
                percentage: getGoalReport.year.success_percentage
              })
            "
          />
        </v-col>

        <v-col cols="12">
          <ListItemSalesFigures
            icon="$flag"
            color="accent"
            :title="$t('sales_figure_planned_sales')"
            title-color="main--text lighten-2"
            :subtitle="yearTerm"
            :value="getGoalReport.year.goal_to_date"
            value-color="main--text lighten-2"
          />
        </v-col>

        <v-col cols="12">
          <ListItemSalesFigures
            icon="$flag"
            color="accent"
            :title="
              $t('sales_figure_term_goal', { term: getGoalReport.year.term })
            "
            title-color="main--text lighten-2"
            :value="getGoalReport.year.goal"
            value-color="main--text lighten-2"
          />
        </v-col>
      </v-row>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import ListItemSalesFigures from "@/components/ui/ListItemSalesFigures";
import AchievementRateChart from "@/components/ui/AchievementRateChart";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";

export default {
  name: "GoalsCardSalesFigures",
  components: { AchievementRateChart, ListItemSalesFigures, CardBase },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapGetters(["getGoalReport"]),
    month() {
      return dayjs(this.getGoalReport.date).format("M");
    },
    today() {
      return dayjs(this.getGoalReport.date).format("M月D日");
    },
    monthTerm() {
      return `${dayjs(this.getGoalReport.date)
        .date(1)
        .format("M/D")} ~ ${dayjs(this.getGoalReport.date).format("M/D")}`;
    },
    yearTerm() {
      return `
      ${dayjs(this.getGoalReport.year.start_date).format("M/D")}
      ～
      ${dayjs(this.getGoalReport.date).format("M/D")}`;
    },
    salesTerm() {
      return `
      ${this.getGoalReport.year.term}期
      ${dayjs(this.getGoalReport.year.start_date).format("YYYY年M月")}
      ～
      ${dayjs(this.getGoalReport.year.end_date).format("YYYY年M月")}
      `;
    }
  }
};
</script>

<style scoped>
.sales-figures-divider {
  border: 2px solid #efefef !important;
}
</style>
