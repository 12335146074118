<template>
  <v-text-field
    v-model="_value"
    :type="isFocused ? 'tel' : 'text'"
    min="0"
    inputmode="numeric"
    class="custom-currency-input text-center"
    :color="color"
    hide-details
    flat
    :height="height"
    @blur="
      isFocused = false;
      $emit('input-lose-focus');
    "
    @focus="isFocused = true"
    @keypress="isNumberKey($event)"
    @keydown.48="handleZero"
    @keydown.96="handleZero"
    @keydown.delete="handleDelete($event)"
  >
  </v-text-field>
</template>

<script>
import { toCurrency } from "@/filters";
import mixins from "@/mixins/index";

export default {
  name: "InputCurrency",
  mixins: [mixins],
  props: {
    value: {
      type: Number,
      default: 0,
      required: true
    },
    max: {
      type: Number,
      default: 10000000,
      required: false
    },
    color: {
      type: String,
      default: "primary",
      required: false
    },
    height: {
      type: Number,
      default: 20,
      required: false
    }
  },
  data() {
    return {
      isFocused: false
    };
  },
  computed: {
    // TODO: If possible emit changes after user completed input instead of realtime.
    _value: {
      get: function() {
        if (this.isFocused) {
          return this.value.toString();
        } else {
          return toCurrency(this.value);
        }
      },
      set: function(modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.$emit("input-changed");
      }
    }
  },
  methods: {
    handleDelete(event) {
      if (this.value === 0) event.preventDefault();
    },
    handleZero(event) {
      if (this.value === 0) event.preventDefault();
    }
  }
};
</script>

<style scoped>
.custom-currency-input {
  background: #f5f5f5 !important;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px;
}

.custom-currency-input >>> input {
  text-align: center;
  margin-bottom: 8px;
}
.custom-currency-input >>> .v-input__slot:before {
  border-style: none !important;
}

.custom-currency-input >>> .v-input__slot:after {
  border-style: none !important;
}
</style>
