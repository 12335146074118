<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint="0"
      :items-per-page="12"
      class="target-table"
    >
      <template v-slot:header.target_monthly_sale="{ header }">
        <div class="font-weight-medium main--text text-center text-heading-2">
          {{ header.text }}
        </div>
      </template>

      <template v-slot:header.actual_sale="{ header }">
        <div class="text-center">
          <div class="font-weight-medium main--text text-heading-2">
            {{ header.text }}
          </div>
          <div
            v-if="!editable"
            class="font-weight-medium main--text lighten-3 text-heading-1"
          >
            {{ $t("achievement_rate") }}
          </div>
        </div>
      </template>

      <template v-slot:item.month="{ item }">
        <div class="font-weight-bold text-heading-5">{{ item.month }}月</div>
      </template>

      <template v-slot:item.target_monthly_sale="{ item }">
        <div class="text-center text-heading-6">
          <NumberInput
            class="target--input"
            :class="item.is_past ? 'target-past' : 'target-current'"
            v-if="editable"
            v-model="item.target_monthly_sale"
            :height="20"
            @input-changed="$emit('monthly-value-change')"
          />

          <div
            :class="item.is_past ? 'main--text lighten-3' : 'secondary--text'"
            v-else
          >
            {{ item.target_monthly_sale | toCurrency }}
          </div>
        </div>
      </template>

      <template v-slot:item.actual_sale="{ item }">
        <div class="text-center py-2">
          <div class="font-weight-medium main--text text-heading-5">
            {{ getActualSaleText(item.actual_sale) }}
          </div>
          <div
            v-if="!editable"
            class="font-weight-regular text-heading-5"
            :class="
              getActualSaleAchievedTextColor(
                item.target_monthly_sale,
                item.actual_sale
              )
            "
          >
            {{
              $t("achieved", {
                percent: getSaleAchievementPercentage(
                  item.target_monthly_sale,
                  item.actual_sale
                )
              })
            }}
          </div>
        </div>
      </template>
    </v-data-table>

    <v-divider></v-divider>
  </div>
</template>

<script>
import { toCurrency } from "@/filters";
import NumberInput from "@/components/ui/InputCurrency";

export default {
  name: "TableMonthlyTarget",
  components: { NumberInput },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    editable: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    referenceValuesSum() {
      let temp = 0;
      for (const item of this.monthlyReferenceValue) {
        temp += item.referenceValue;
      }
      return temp;
    }
  },

  data() {
    return {
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "month",
          width: 70
        },
        {
          text: this.$t("monthly_goal"),
          sortable: false,
          value: "target_monthly_sale",
          width: this.editable ? 140 : 100
        },
        {
          text: this.$t("actual_sales"),
          sortable: false,
          value: "actual_sale"
        }
      ],
      monthlyReferenceValue: [
        {
          month: 1,
          referenceValue: 100
        },
        {
          month: 2,
          referenceValue: 80
        },
        {
          month: 3,
          referenceValue: 120
        },
        {
          month: 4,
          referenceValue: 120
        },
        {
          month: 5,
          referenceValue: 100
        },
        {
          month: 6,
          referenceValue: 100
        },
        {
          month: 7,
          referenceValue: 100
        },
        {
          month: 8,
          referenceValue: 80
        },
        {
          month: 9,
          referenceValue: 100
        },
        {
          month: 10,
          referenceValue: 100
        },
        {
          month: 11,
          referenceValue: 100
        },
        {
          month: 12,
          referenceValue: 130
        }
      ]
    };
  },

  methods: {
    getActualSaleAchievedTextColor(target, sale) {
      let percentage = this.getSaleAchievementPercentage(target, sale);
      if (sale === 0) {
        return "goals-no-data--text";
      } else if (sale > target || percentage >= 100) {
        return "goals-achieved--text";
      } else {
        return "goals-not-achieved--text";
      }
    },
    getActualSaleText(sale) {
      return sale !== 0 ? toCurrency(sale) : "¥--";
    },
    getSaleAchievementPercentage(target, sale) {
      if (target === 0) return 0;
      return Math.round((sale / target) * 100);
    },
    getDistributionFactorOfAMonth(month) {
      return Math.round(
        100 /
          this.monthlyReferenceValue[month].referenceValue /
          this.referenceValuesSum
      );
    }
  }
};
</script>

<style>
.monthly-target--input >>> input {
  font-size: 18px !important;
}

.target-past >>> input {
  color: #888888 !important;
}

.target-current >>> input {
  color: #0094ff !important;
}
</style>
